import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`PSI University Offers 2021 - Our 25 graduates have amassed 89 university acceptances across 10 countries. PSI does not limit the number of universities where students may apply.  Twenty-two of our graduates received acceptance offers from one of their three top choice universities.  Two of our students are still waiting to receive offers, as universities in the countries where they applied have not announced acceptances as of yet.`}<br parentName="p"></br>{`
`}{`Universities where PSI students have decided to matriculate are marked as ✅.`}</p>
    <Wrapper columns={3} sm={{
      column: 1
    }} row fullWidth mdxType="Wrapper">
      <Wrapper padding={{
        right: 'md'
      }} mdxType="Wrapper">
        <h4 {...{
          "id": "-canada",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-canada",
            "aria-label": " canada permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇨🇦 Canada`}</h4>
        <p>{`Mount Allison University`}<br parentName="p"></br>{`
`}{`University of British Columbia`}<br parentName="p"></br>{`
`}{`University of McGill`}<br parentName="p"></br>{`
`}{`✅ University of Toronto`}</p>
        <h4 {...{
          "id": "-france",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-france",
            "aria-label": " france permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇫🇷 France`}</h4>
        <p>{`✅ Essec Business School`}<br parentName="p"></br>{`
`}{`Université du Littoral - Site de Boulogne-sur-Mer, Université de Paris`}<br parentName="p"></br>{`
`}{`Université de Nîmes`}<br parentName="p"></br>{`
`}{`Université Paris 8`}<br parentName="p"></br>{`
`}{`Université Paris Nanterre`}<br parentName="p"></br>{`
`}{`Université de Versailles - Saint Quentin en Yvelines`}</p>
        <h4 {...{
          "id": "-ireland",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-ireland",
            "aria-label": " ireland permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇮🇪 Ireland`}</h4>
        <p>{`National University of Ireland Galway`}<br parentName="p"></br>{`
`}{`University College Dublin`}<br parentName="p"></br>{`
`}{`Trinity College Dublin`}</p>
        <h4 {...{
          "id": "-italy",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-italy",
            "aria-label": " italy permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇮🇹 Italy`}</h4>
        <p>{`Marist University`}<br parentName="p"></br>{`
`}{`✅ University of Bocconi`}</p>
        <h4 {...{
          "id": "-the-netherlands",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-the-netherlands",
            "aria-label": " the netherlands permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇫🇷 The Netherlands`}</h4>
        <p>{`✅ Erasmus University Rotterdam`}<br parentName="p"></br>{`
`}{`Groeningen University`}<br parentName="p"></br>{`
`}{`✅ Maastricht University Venlo`}<br parentName="p"></br>{`
`}{`Utrecht University`}</p>
      </Wrapper>
      <Wrapper padding={{
        right: 'md'
      }} mdxType="Wrapper">
        <h4 {...{
          "id": "-poland",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-poland",
            "aria-label": " poland permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇵🇱 Poland`}</h4>
        <p>{`✅ Polish Japanese Academy of Informationƒ`}</p>
        <h4 {...{
          "id": "-spain",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-spain",
            "aria-label": " spain permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇪🇸 Spain`}</h4>
        <p>{`✅ IE University`}</p>
        <h4 {...{
          "id": "-united-kingdom",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-united-kingdom",
            "aria-label": " united kingdom permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇬🇧 United Kingdom`}</h4>
        <p>{`✅ Birkbeck University of London`}<br parentName="p"></br>{`
`}{`Brunel University of London`}<br parentName="p"></br>{`
`}{`City, University of London`}<br parentName="p"></br>{`
`}{`Lancaster University`}<br parentName="p"></br>{`
`}{`King’s College London`}<br parentName="p"></br>{`
`}{`Newcastle University`}<br parentName="p"></br>{`
`}{`Queen Mary University of London`}<br parentName="p"></br>{`
`}{`Regent’s University London`}<br parentName="p"></br>{`
`}{`Royal Holloway, University of London`}<br parentName="p"></br>{`
`}{`University of Aberdeen`}<br parentName="p"></br>{`
`}{`✅ University of the Arts London`}<br parentName="p"></br>{`
`}{`University of Bath`}<br parentName="p"></br>{`
`}{`University College London`}<br parentName="p"></br>{`
`}{`University for the Creative Arts`}<br parentName="p"></br>{`
`}{`University of Dundee`}<br parentName="p"></br>{`
`}{`University of East Anglia`}<br parentName="p"></br>{`
`}{`✅ University of Edinburgh`}<br parentName="p"></br>{`
`}{`University of Glasgow`}<br parentName="p"></br>{`
`}{`✅ University of St. Andrews`}<br parentName="p"></br>{`
`}{`University of Strathclyde`}<br parentName="p"></br>{`
`}{`University of Warwick`}<br parentName="p"></br>{`
`}{`University of York`}</p>
      </Wrapper>
      <Wrapper padding={{
        right: 'md'
      }} mdxType="Wrapper">
        <h4 {...{
          "id": "-united-states-of-america",
          "style": {
            "position": "relative"
          }
        }}><a parentName="h4" {...{
            "href": "#-united-states-of-america",
            "aria-label": " united states of america permalink",
            "className": "anchor before"
          }}><svg parentName="a" {...{
              "aria-hidden": "true",
              "focusable": "false",
              "height": "16",
              "version": "1.1",
              "viewBox": "0 0 16 16",
              "width": "16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
              }}></path></svg></a>{`🇺🇸 United States of America`}</h4>
        <p>{`American University`}<br parentName="p"></br>{`
`}{`✅ Arizona State University`}<br parentName="p"></br>{`
`}{`✅ Babson College`}<br parentName="p"></br>{`
`}{`Boston University`}<br parentName="p"></br>{`
`}{`✅ Brown University`}<br parentName="p"></br>{`
`}{`Carnegie Mellon University`}<br parentName="p"></br>{`
`}{`Dickinson College`}<br parentName="p"></br>{`
`}{`✅ Macalester College`}<br parentName="p"></br>{`
`}{`Michigan State University`}<br parentName="p"></br>{`
`}{`Minerva Schools at KGIƒ`}<br parentName="p"></br>{`
`}{`The New School`}<br parentName="p"></br>{`
`}{`New York University Honors College`}<br parentName="p"></br>{`
`}{`Northeastern University`}<br parentName="p"></br>{`
`}{`Pace University`}<br parentName="p"></br>{`
`}{`Pennsylvania State Unƒiversity`}<br parentName="p"></br>{`
`}{`Reed College`}<br parentName="p"></br>{`
`}{`Smith College`}<br parentName="p"></br>{`
`}{`Saint Louis University`}<br parentName="p"></br>{`
`}{`St. Olaf College`}<br parentName="p"></br>{`
`}{`Suffolk University`}<br parentName="p"></br>{`
`}{`University of California, Berkeley`}<br parentName="p"></br>{`
`}{`University of California, Los Angeles`}<br parentName="p"></br>{`
`}{`University of California, San Diego`}<br parentName="p"></br>{`
`}{`University of Illinois at Chicago`}<br parentName="p"></br>{`
`}{`University of Maryland`}<br parentName="p"></br>{`
`}{`✅ University of Michigan Ann Arbor`}<br parentName="p"></br>{`
`}{`✅ University of Michigan Dearborn`}<br parentName="p"></br>{`
`}{`University of Miami`}<br parentName="p"></br>{`
`}{`University of Puget Sound`}<br parentName="p"></br>{`
`}{`University of Richmond`}<br parentName="p"></br>{`
`}{`University of Virginia`}<br parentName="p"></br>{`
`}{`Villanova University`}<br parentName="p"></br>{`
`}{`✅ Whitman College`}</p>
      </Wrapper>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      